import type { EditoProductPushModel, FlexibleSectionModel, ProductItem } from '~/diptyqueTheme/types/contentful';
export const adaptEditoProductPush = (data: FlexibleSectionModel): EditoProductPushModel => {
  const items = data.itemsCollection?.items || [];

  return {
    ...data,
    etitle: data.eTitle,
    visualEntry: data.visual,
    sku: items[0]?.sku,
    itemsCollection: {
      items: items.map((item) => {
        if ('eTitle' in item) {
          return item as ProductItem;
        }
        return {
          eTitle: item.groupTitle,
          sku: item.sku,
          visibility: item.visibility || []
        } as ProductItem;
      })
    }
  };
};
